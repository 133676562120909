import URLRoutes from "urlRoutes";
import { adminAppSettings, adminList, adminPaDetails, adminPaList, homeList, initiativeList, internsList } from "./list";
import { addChargeCodeStatusRext, addEditProjectDetailsRext, editProjectDetailsRext, getRevenueCreditRext, projectGetEmailCode, projectSubmitToPaDetails, searchBCGUsers } from "store/rext";

export function listAdmins() {
  return adminList.request({
    url: URLRoutes.server.listAdmins,
    method: "get"
  })
}

export function listInitiatives(practiceAreaId: string) {
  return initiativeList.request({
    url: practiceAreaId ? `${URLRoutes.server.listInitiativesWithPa}` : `${URLRoutes.server.listInitiatives}`,
    method: "get",
    urlParams: {
      practiceAreaId: practiceAreaId || ""
    }
  })
}

export function deleteInitiative(id: number) {
  return initiativeList.deleteRequest({
    url: URLRoutes.server.deleteInitiative,
    method: "delete",
    urlParams: {
      id,
    }
  })
}

export function createInitiative(body: any) {
  return initiativeList.addRequest({
    url: URLRoutes.server.createInitiatives,
    method: "post",
    body
  })
}

export function requestInternsList() {
  return internsList.request({
    url: URLRoutes.server.listInterns,
    method: "get"
  })
}

export function requestHomeList(data: object) {
  return homeList.request({
    method: "put",
    url: URLRoutes.server.homeList,
    body: data
  })
}

export function deleteProjectById(id: string) {
  return homeList.deleteRequest({
    url: URLRoutes.server.updateProject,
    method: "delete",
    urlParams: {
      id,
    }
  })
}

export function requestPAList() {
  return adminPaList.request({
    url: URLRoutes.server.listAdminPa,
    method: "get"
  })
}

export function updatePAList(paId: any, body: any) {
  return adminPaList.editRequest({
    url: URLRoutes.server.listAdminPaUpdate,
    method: "put",
    urlParams: {
      paId
    },
    body
  })
}

export function requestPADetails(paId: string) {
  return adminPaDetails.request({
    url: URLRoutes.server.listAdminPaDetails,
    method: "get",
    urlParams: {
      paId,
    }
  })
}

export function getAllBCGUsersByName(searchText: string) {
  return searchBCGUsers.request({
    method: "post", url: URLRoutes.server.people, body: {
      query: `{
          searchFilter (aliasMatching:["title"] 
          compositeFilter: {logicOperator: AND 
          subFilters: [
          { logicOperator: OR  subFilters: [   
          { valueFilter: { field: "preferredName=*${searchText}*" } } 
          { valueFilter: { field: "preferredFirstName=*${searchText}*" } } 
          { valueFilter: { field: "preferredLastName=*${searchText}*" } }
          ]	}
          ]} 
          resultFilters:[ "jobPosition", "hostOfficeId", "hostOfficeRegion", "alumni"] dataSet:BCG_ALL limit: 50 offset:0){ 
          employees{ 
            id preferredName businessTitle hostOfficeRegion email
          }
        }}`,
      variables: {}
    }
  }, {
    transformation: (item: any) => item?.searchFilter?.employees
  })
}

export function getAllBCGUsersByNameEmail(searchText: string) {
  return searchBCGUsers.request({
    method: "post", url: URLRoutes.server.people, body: {
      query: `{
          searchFilter (aliasMatching:["title"] 
          compositeFilter: {logicOperator: AND 
          subFilters: [
          { logicOperator: OR  subFilters: [   
          { valueFilter: { field: "preferredName=*${searchText}*" } } 
          { valueFilter: { field: "preferredFirstName=*${searchText}*" } } 
          { valueFilter: { field: "preferredLastName=*${searchText}*" } }
           { valueFilter: { field: "workEmail=*${searchText}*" } }
          ]	}
          ]} 
          resultFilters:[ "jobPosition", "hostOfficeId", "hostOfficeRegion", "alumni"] dataSet:BCG_ALL limit: 50 offset:0){ 
          employees{ 
            id preferredName businessTitle hostOfficeRegion email
          }
        }}`,
      variables: {}
    }
  }, {
    transformation: (item: any) => item?.searchFilter?.employees
  })
}

export function createProject(body: any) {
  // addProjectDetailsRext
  return addEditProjectDetailsRext.request({
    url: URLRoutes.server.project,
    method: "post",
    body
  })
}

export function updateProject(body: any, id: string) {
  return addEditProjectDetailsRext.request({
    url: URLRoutes.server.updateProject,
    method: "put",
    body,
    urlParams: {
      id
    }
  })
}

export function projectSubmitToPa(body: any) {
  return projectSubmitToPaDetails.request({
    url: URLRoutes.server.projectSubmitToPa,
    method: "post",
    body
  })
}

export function getEmailCode(approvalCode: any) {
  return projectGetEmailCode.request({
    url: URLRoutes.server.emailCode,
    method: "get",
    urlParams: {
      id: approvalCode
    }
  })
}

export function requestAppSettings() {
  return adminAppSettings.request({
    url: URLRoutes.server.appSettings,
    method: "get"
  })
}

export function createAppSettings(body: any) {
  return adminAppSettings.editRequest({
    url: URLRoutes.server.appSettings,
    method: "put",
    body
  })
}

export function chargeCodeStatusSave(body: any) {
  return addChargeCodeStatusRext.request({
    url: URLRoutes.server.projectStatusSave,
    method: "put",
    body
  })
}

export function getRevenueCredit(id: number) {
  return getRevenueCreditRext.request({
    url: URLRoutes.server.revenueCredit,
    method: "get",
    urlParams: {
      id
    }
  })
}