import { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { FormErrors } from "./FormErrors";

export function FormTextArea(props: any) {

    const { label, name, errors, register, validation, rows, disabled, readOnly = false, placeholder } = props;

    return (
        <Fragment>
            <Form.Field error={errors && !!errors[name]}>
                <label className={validation && validation.required && 'required'}>{label}</label>
                <textarea placeholder={placeholder || label} rows={rows} readOnly={readOnly} {...register(name, validation)} disabled={disabled} />
                {
                    errors &&
                    <FormErrors
                        label={label}
                        name={name}
                        errors={errors}
                    />
                }
            </Form.Field>
        </Fragment>
    );
}
