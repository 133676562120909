import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { watchPeopleEvent } from "./people";
import { adminAppSettings, adminList, adminPaDetails, adminPaList, homeList, initiativeList, internsList, projectList } from "store/list";
import { appRext, initiativeDropdownRext, addProjectDropdownRext, homeDropdownRext, searchBCGUsers, projectHistoryDetails, projectSubmitToPaDetails, projectGetEmailCode, adminChargeCodeRext, editProjectDetailsRext, addEditProjectDetailsRext, requestSaveChangesRext, requestSubmitToFinanceRext, requestUpdateFinanceRext, projectStatusDropdownRext, addChargeCodeStatusRext, getRevenueCreditRext, requestSubmitForReviewRext, requestUserPrivilageRolesRext } from "store/rext";

export default function* root(): SagaIterator {
  yield all([
    fork(appRext.saga),
    fork(adminList.saga),
    fork(initiativeList.saga),
    fork(initiativeDropdownRext.saga),
    fork(internsList.saga),
    fork(watchPeopleEvent),
    fork(addProjectDropdownRext.saga),
    fork(homeDropdownRext.saga),
    fork(homeList.saga),
    fork(adminPaList.saga),
    fork(adminPaDetails.saga),
    fork(searchBCGUsers.saga),
    fork(projectList.saga),
    fork(editProjectDetailsRext.saga),
    fork(addEditProjectDetailsRext.saga),
    fork(projectHistoryDetails.saga),
    fork(projectSubmitToPaDetails.saga),
    fork(adminAppSettings.saga),
    fork(requestSaveChangesRext.saga),
    fork(requestSubmitToFinanceRext.saga),
    fork(requestUpdateFinanceRext.saga),
    fork(projectGetEmailCode.saga),
    fork(adminChargeCodeRext.saga),
    fork(projectStatusDropdownRext.saga),
    fork(addChargeCodeStatusRext.saga),
    fork(getRevenueCreditRext.saga),
    fork(requestSubmitForReviewRext.saga),
    fork(requestUserPrivilageRolesRext.saga)
  ])
}