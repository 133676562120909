import OktaAuth from "@okta/okta-auth-js";
import { getListState } from "baseProvider/list";
import { getRextState } from "baseProvider/rext";
import { ApplicationState, ModalState } from "store/reducers";
import { IPeople } from "store/reducers/people";

export function getModalState(state: ApplicationState): ModalState {
  return state.modalState
}

export function getOktaAuthState(state: ApplicationState): OktaAuth {
  return state.applicationContext.okatAuth
}

export function getToken(state: ApplicationState): string {
  const { okatAuth } = state.applicationContext;
  if (okatAuth) {
    return `Bearer ${okatAuth.getAccessToken()!}`;
  }
  return "";
}

export function getBaseUrl(isPeople: boolean): (state: ApplicationState) => string {
  return (state: ApplicationState): string => {
    if (isPeople) {
      return process.env.REACT_APP_PEOPLE_API_URL!
    }
    return process.env.REACT_APP_BASE_API_URL!;
  }
}

export function getBaseUrlAPIKEY(isPeople: boolean): (state: ApplicationState) => string {
  return (state: ApplicationState): string => {
    if (isPeople) {
      return process.env.REACT_APP_PEOPLE_XAPI_KEY!
    }
    return process.env.REACT_APP_X_API_KEY!;
  }
}


export function getAppState(state: ApplicationState) {
  return getRextState(state.appState, undefined!)
}

export function getAdminList(state: ApplicationState) {
  return getListState(state.adminListState, [])
}

export function getInternsList(state: ApplicationState) {
  return getListState(state.internsList, [])
}

export function getInitiativeDropdown(state: ApplicationState) {
  return getRextState(state.initiativeDropdownState, {})
}

export function getInitiavesList(state: ApplicationState) {
  return getListState(state.initiativeListState, [])
}

export function getPeopleState(peopleId: string) {
  return (state: ApplicationState): IPeople => state.peopleState[peopleId];
}

export function getAllPeopleState(state: ApplicationState) {
  return state.peopleState;
}

export function getAddProjectDropdown(state:ApplicationState){
  return getRextState(state.addProjectDropdownState,{})
}

export function getHomeDropdown(state:ApplicationState){
  return getRextState(state.homeDropdownState,{})
}

export function getHomeList(state : ApplicationState) {
  return getListState(state.homeListState, [])
}

export function getAdminPAList(state: ApplicationState) {
  return getListState(state.adminPAListState, [])
}

export function getAdminPADetails(state: ApplicationState) {
  return getListState(state.adminPADetailsState, [])
}

export function getSearchBCGUsers(state:ApplicationState) {
  return getRextState(state.serachBcgUserState, [])
}

export function getEditProjectDetails(state:ApplicationState){
  return getRextState(state.editProjectDetailsState,{})
}

export function getAddEditProjectDetails(state:ApplicationState){
  return getRextState(state.addEditProjectDetailsState,{})
}

export function getProjectList(state:ApplicationState){
  return getListState(state.projectListState,{})
}

export function getProjectHistoryList(state:ApplicationState){
  return getRextState(state.projectHistoryListState, [])
}

export function getProjectApprovalDetails(state:ApplicationState){
  return getRextState(state.projectSubmitToPaState, {})
}

export function getPaSaveChangeDetails(state:ApplicationState){
  return getRextState(state.projectSaveChangesState, {})
}

export function getProjectFinanceDetails(state:ApplicationState){
  return getRextState(state.projectSubmitToFinanceState, {})
}

export function getEmailCodeResponseState(state:ApplicationState){
  return getRextState(state.projectEmailCodeState, {})
}

export function getProjectUpdateState(state:ApplicationState){
  return getRextState(state.projectFinanceStatusState, {})
}

export function getAdminAppSettings(state:ApplicationState){
  return getListState(state.adminAppSettingsState, {})
}

export function getAdminChargeCodeState(state:ApplicationState){
  return getRextState(state.adminChargeCodeState, {})
}

export function getChargeCodeStatusProjectDropdown(state:ApplicationState){
  return getRextState(state.chargeCodeStatusDropdownState,{})
}

export function getChargeCodeSaveState(state:ApplicationState){
  return getRextState(state.addChargeCodeState, {})
}

export function getRevenueCreditNameState(state:ApplicationState){
  return getRextState(state.getRevenueCreditState, {})
}

export function getProjectSubmitReviewState(state:ApplicationState){
  return getRextState(state.projectSubmitForReviewState, {})
}

export function getUserPrivilageRolesState(state:ApplicationState){
  return getRextState(state.userPrivilageRolesState, {})
}