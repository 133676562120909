import { FormInput, FormTextArea } from "components/FormInputs";
import TableComponent from "components/TableComponent/TableComponent";
import SingleUser from "containers/PAList/SingleUser";
import { Column } from "interface";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Divider, Form, Label, Modal, Popup, Table } from "semantic-ui-react";
import { getAllBCGUsersByNameEmail } from "store/list";
import { getSearchBCGUsers } from "store/selectors";

interface Props {
    hideModal: () => void;
    onSelect: (staff: any) => void;
    type: string;
}

const columns: Column[] = [
    { name: 'Name', value: 'name', sort: false },
    { name: 'Email', value: 'email', sort: false },
    { name: 'Action', value: 'action', sort: false }
];

const ReviewProject = (props: Props) => {
    const dispatch = useDispatch();

    const { data: searchUserState, fetching } = useSelector(getSearchBCGUsers);

    const {
        hideModal
    } = props;

    const { register, handleSubmit, reset, control } = useForm();

    const [isHrId, setIsHrId] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [userListData, setUserListData] = useState<any>();
    const [selectedUserListData, setSelectedUserListData] = useState<any>([]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'sharePointUrls'
    });

    const findUsers = (name: string) => {
        setSearchTerm(name)
        if (props.type == undefined || props.type !== 'hrid') {
            dispatch(getAllBCGUsersByNameEmail(name));
        }
    }

    const onSubmit = handleSubmit(data => {
        if (data.search) {
            findUsers(data.search);
        }
    });

    const onSubmitSahrePoint = handleSubmit(data => {
        const payload = {
            reviewerEmailIds: selectedUserListData
                ?.map((user: any) => user?.email)
                .filter((email: string) => email), // Filter out empty strings
            sharePointUrls: data?.sharePointUrls?.map((url: any) => url?.value),
            userMessage: data?.userMessage
        }
        props.onSelect(payload);
    });

    const onStaffSelect = (staff: any) => {
        props.onSelect(staff);
    }

    const onSelectUser = (staff: any) => {
        setSelectedUserListData((prevList: any) => {
            if (!prevList.some((user: any) => user.id === staff.id)) {
                return [...prevList, staff];
            }
            return prevList;
        });
    }

    const renderRows = (item: any) => {
        const { id } = item;
        return (
            <Table.Row key={id}>
                <Table.Cell>{item?.preferredName}</Table.Cell>
                <Table.Cell>{item?.email}</Table.Cell>
                <Table.Cell>
                    <a
                        className="cursor-pointer black-color"
                        onClick={() => setSelectedUserListData((prevList: any) => prevList.filter((user: any) => user.id !== item.id))}
                    >✖</a>
                </Table.Cell>
            </Table.Row>
        )
    }

    const renderUserList = (user: any) => {
        const { id, preferredName, email } = user;
        return (
            <Label
                key={id}
                className="user-chip cursor-pointer"
                onClick={() => onSelectUser(user)}
            >
                {preferredName} {email && (
                    <>
                        (<Label.Detail>{email}</Label.Detail>)
                    </>
                )}
            </Label>
        )
    }

    const renderSharePoint = (item: any, index: number) => {
        return (
            <div key={`sharepoint-url${index}`} className="flex flex-align-center width-100 margin-t-2 margin-b-2">
                <div className="width-80">
                    <FormInput
                        name={`sharePointUrls[${index}].value`}
                        register={register}
                        customClass="width-80"
                        placeholder="Enter SharePoint URL here"
                    />
                </div>
                {index !== 0 &&
                    <div className="width-20 margin-l-2">
                        <Button size='mini' type="button" onClick={() => remove(index)}>✖</Button>
                    </div>
                }
            </div>
        )
    }

    useEffect(() => {
        if (searchUserState.length) {
            setUserListData(searchUserState)
        }
    }, [searchUserState]);

    useEffect(() => {
        setIsHrId(false);

        reset({
            sharePointUrls: [{ value: '' }],
            userMessage: ''
        })

        return () => {
            setUserListData([]);
            setSearchTerm('')
        }
    }, []);

    useEffect(() => {
        if (props.type !== undefined && props.type === 'hrid' && searchTerm !== '') {
            setIsHrId(true)
        }
    }, [searchTerm]);

    return (
        <Modal
            size="tiny"
            dimmer
            open
            onClose={hideModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <div className="flex flex-justify-between">
                    <div className="flex-justify-start flex-align-self-center flex-basis-80">
                        <h5 className="margin-0">Submit Project for Review</h5>
                        <h6 className="modal-sub-title">Please complete the fields below. An email with the project details will be sent to you and the designated recipients for review and approval. Ensure you include the appropriate Practice Area contacts.</h6>
                    </div>
                    <div className="flex-justify-end">
                        <Button basic className="cursor-pointer" size='mini' onClick={hideModal}>close &#10006;</Button>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <p className="margin-0 text-bold text-md">{`${props.type === 'hrid' ? 'Enter User HR ID' : 'Add Email Recipients - one email will be sent to all recipients'}:`}</p>
                <span className="margin-0 text-md">Enter any part of the Staff Name or Email:</span>

                <Form 
                className="margin-b-6"
                style={{ marginTop: '-8px' }}
                size="small" 
                onSubmit={onSubmit}
                >
                    <div className="width-100 search-form-wrap">
                        <div className="margin-t-4 flex flex-justify-start flex-align-center">
                            <FormInput
                                name='search'
                                register={register}
                                customClass="width-100 margin-0"
                            />
                            <div className="width-20 margin-l-2">
                                <Button size='mini' loading={fetching} primary type="submit">
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>

                <Divider />
                {
                    !isHrId && userListData?.length && searchTerm !== '' &&
                    <div className="margin-t-2 margin-b-2">
                        <p className="text-bold text-md">Found {userListData?.length} results, click to select:</p>
                        <div className="user-chips-wrap">
                            {userListData?.map(renderUserList)}
                        </div>
                    </div>
                }

                {selectedUserListData?.length > 0 && (
                    <>
                        <p className="text-bold text-md margin-t-4 margin-b-1">Selected Recipients ({selectedUserListData?.length}):</p>
                        <TableComponent noDataMessage="There are no items." sortable={false} celled={false}
                            fetching={false} columns={columns} rows={userListData}>
                            {
                                selectedUserListData?.map(renderRows)
                            }
                        </TableComponent>
                    </>
                )}

                <Form
                    onSubmit={onSubmitSahrePoint}
                    className="margin-t-6"
                >
                    <p className="margin-t-2 text-bold text-md">Add Sharepoint links with any additional information (Optional)</p>
                    {fields?.map(renderSharePoint)}
                    <Button type="button" size='mini' onClick={() => append({ value: '' })}>
                        Add More
                    </Button>

                    <div className="margin-t-5 margin-b-4">
                        <FormTextArea
                            label={(
                                <div className="flex">
                                    <label>Add a message with additional context about the project or details to share with the recipients (Optional)
                                        <Popup
                                            content="Please do not add any links in this text box."
                                            trigger={<img
                                                style={{ verticalAlign: "bottom" }}
                                                className="margin-l-2 padding-t-2 cursor-pointer"
                                                src="../info.jpg" />}
                                        />
                                    </label>
                                </div>
                            )}
                            name='userMessage'
                            register={register}
                            rows={3}
                            placeholder="Enter your message here"
                        />

                    </div>

                    <div className="flex flex-justify-center">
                        <Button type="submit" primary>
                            Submit
                        </Button>
                    </div>
                </Form>

                {isHrId && searchTerm !== '' && (<SingleUser searchTerm={searchTerm} columns={columns} onStaffSelect={onStaffSelect} />)}

            </Modal.Content>

        </Modal>
    )
}

export default ReviewProject;