import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalOperation } from "store/actions";
import { getModalState } from "store/selectors";
import AlertModalView from "./AlertModal";
import StaffChooserView from "./StaffChooser";
import FpaIpaModalView from "./FpaIpaModal";
import ReviewProjectModalView from "./ReviewProject";
import "./modal.scss";

export enum ModalsType {
  AlertModal = "AlertModalView",
  StaffChooserModal = "StaffChooserView",
  FpaIpaModal = "FpaIpaModalView",
  ReviewProjectModal = "ReviewProjectModalView",
}
const modalComponentLookupTable: any = {
  AlertModalView,
  StaffChooserView,
  FpaIpaModalView,
  ReviewProjectModalView,
};


function ModalManager(props: any) {
  const dispatch = useDispatch();
  const modalState = useSelector(getModalState);
  const hideModal = () => {
    dispatch(modalOperation.hideModal());
  }

  const modal = useMemo( () => {
    const { name, modalProps = {} } = modalState || {};
    if(name) {
      const ModalComponent = modalComponentLookupTable[name];
      return (
        <ModalComponent {...modalProps} hideModal={hideModal}/>
      );
    } else {
      return <Fragment/>
    }

  }, [modalState]);

  return <Fragment>{modal}</Fragment>;
}

export default ModalManager;
