
import { createRext, IRext } from "baseProvider/rext";
import { getBaseUrl, getBaseUrlAPIKEY, getToken } from "store/selectors";

export const appRext : IRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "appRext"
})

export const initiativeDropdownRext : IRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "initiativeDropdownRext"
})

export const addProjectDropdownRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "addProjectDropdownRext"
})

export const homeDropdownRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "homeDropdownRext"
})

export const searchBCGUsers: IRext = createRext({
    identity: "searchBCGUsers",
    getBaseUrl: getBaseUrl(true),
    getXAPIToken: getBaseUrlAPIKEY(true),
    getToken: getToken
})

export const editProjectDetailsRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "editProjectDetails"
})

export const addEditProjectDetailsRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "addProjectDetailsRext"
})

export const projectHistoryDetails = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "projectHistoryDetails"
})

export const projectSubmitToPaDetails = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "projectSubmitToPaDetails"
})

export const projectGetEmailCode = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "projectGetEmailCode"
})

export const adminChargeCodeRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "adminChargeCode"
})

export const requestSaveChangesRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "requestSaveChangesRext"
})

export const requestSubmitToFinanceRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "requestSubmitToFinanceRext"
})

export const requestUpdateFinanceRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "requestUpdateFinanceRext"
})

export const projectStatusDropdownRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "projectStatusDropdownRext"
})

export const addChargeCodeStatusRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "addChargeCodeStatusRext"
})

export const getRevenueCreditRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "getRevenueCreditRext"
})

export const requestSubmitForReviewRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "requestSubmitForReviewRext"
})

export const requestUserPrivilageRolesRext = createRext({
    getBaseUrl: getBaseUrl(false),
    getXAPIToken: getBaseUrlAPIKEY(false),
    getToken: getToken,
    identity: "requestUserPrivilageRolesRext"
})